<template>
  <objetivosForm
    btnSubmit="Crear Objetivo"
    :objetivo="data"
    processType="created"
    @processForm="agregar"
  />
</template>

<script>

import { mapActions } from 'vuex'
import store from '@/store/index'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

import objetivosForm from './ObjetivosForm.vue'

export default {
  components: {
    objetivosForm,
  },
  data() {
    return {
      data: {
        idEje: null,
        abreviatura: '',
        nombre: '',
        priorizacion: null,
        estado: 'Activo',
      },
    }
  },
  methods: {
    ...mapActions({ addObjetivoPersonalizado: 'objetivos/addObjetivoPersonalizado' }),
    agregar(objetivo) {
      this.addObjetivoPersonalizado(objetivo).then(() => {
        const errorObjetivos = store.state.objetivos
        const errorMessage = errorObjetivos.errorMessage.errors
        if (!errorObjetivos.error) {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Objetivo creado 👍',
              icon: 'CheckIcon',
              text: `El objetivo "${objetivo.abreviatura}" fue guardado con éxito!`,
              variant: 'success',
            },
          },
          {
            position: 'bottom-right',
            timeout: 3000,
          })
          this.$router.replace({
            name: 'objetivos',
          })
        } else if (errorMessage.nombre) {
          this.$swal({
            title: 'Error!',
            text: `${errorMessage.nombre[0]}!`,
            icon: 'error',
            customClass: {
              confirmButton: 'btn btn-primary',
            },
            buttonsStyling: false,
          })
        } else {
          this.$swal({
            title: 'Error!',
            text: 'Ingreso de datos fraudulento!',
            icon: 'error',
            customClass: {
              confirmButton: 'btn btn-primary',
            },
            buttonsStyling: false,
          })
        }
      })
    },
  },
}
</script>
